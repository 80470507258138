<template>
	<div class="">
		<a-spin :spinning="loading">
			<div class="flex space alcenter">
				<div class="ft20 cl-main ftw600">{{getTitle}}</div>
				<a-button @click="$router.go(-1)">返回</a-button>
			</div>
			
			<div class="mt20 bg-w pd40">
				<div style="width: 1000px;">
					<a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
						
						<a-form-model-item required label="项目类型" help="10字以内">
							<a-input v-model="form.name"  :maxLength="10" placeholder="请输入项目类型" style="width: 300px;"></a-input>
						</a-form-model-item>
						
						<a-form-model-item required label="缩略图" >
							<div class="ft12 ftw400 cl-notice">建议上传200x200px</div>
							<div class="mt10 ml20">
								<upload-img v-model="form.photo"></upload-img>
							</div>
						</a-form-model-item>
						<a-form-model-item required label="服务价格" help="单规格则显示这个价格,多规格则是这个价格起">
							<a-input-number v-model="form.price"  :formatter="value => `${value}元`" placeholder="请输入" :precision="2" :min="0" style="width: 120px;"/>
						</a-form-model-item>
						<a-form-model-item v-if="is_multi == 1" required help="如果门店不能被预约数量为0就行了" label="门店可接待数量">
							<table class="wxb-table list" style="width: 100%;">
								<thead>
									<th>门店</th>
									<th>数量</th>
									
								</thead>
								<tbody>
									<tr v-for="(item,index) in mendian">
										<td>
											{{item.name}}
										</td>
										
										<td>
											<a-input-number v-model="item.num"  placeholder="请输入" :precision="0" :min="0" />
										</td>
									</tr>
								</tbody>
							</table>
							
							
						</a-form-model-item>
						<a-form-model-item v-if="is_multi == 0" required label="可接待数量">
							<a-input-number v-model="form.num"  placeholder="请输入"  :min="0" style="width: 120px;"/>
						</a-form-model-item>
						<a-form-model-item required help="如果填10就是 08:00  08:10  08:20..." label="生成预约时间步长">
							<a-input-number v-model="form.step"  placeholder="请输入"  :min="0" style="width: 120px;"/>分钟
						</a-form-model-item>
						<a-form-model-item required label="服务时间">
							<a-time-picker v-model="form.bg"  valueFormat="HH:mm" format="HH:mm" />
							至
							<a-time-picker v-model="form.end"  valueFormat="HH:mm" format="HH:mm" />
						</a-form-model-item>
						<a-form-model-item  required label="显示预约数量">
							<a-input-number v-model="form.yue_num"  placeholder="请输入"  :min="0" style="width: 120px;"/>
						</a-form-model-item>
						<a-form-model-item  required help="数值越大排序越高" label="排序">
							<a-input-number v-model="form.sort"  placeholder="请输入"  :min="0" style="width: 120px;"/>
						</a-form-model-item>
						<a-form-model-item required label="是否有规格">
							 <a-radio-group v-model="form.is_sku">
							      <a-radio :value="0">
							        无
							      </a-radio>
							      <a-radio :value="1">
							        多规格
							      </a-radio>
							</a-radio-group>
						</a-form-model-item>
						<a-form-model-item v-if="form.is_sku == 0" required label="服务时长">
							<a-input-number v-model="form.need_time"  placeholder="请输入"  :min="0" style="width: 120px;"/>分钟
						</a-form-model-item>
						
						
						<a-form-model-item v-if="form.is_sku == 1" required label="规格属性">
							
							<table class="wxb-table list" style="width: 100%;">
								<thead>
									<th>规格名称</th>
									<th>门店价</th>
									<th>服务时长</th>
									<th>操作</th>
								</thead>
								<tbody>
									<tr v-for="(item,index) in sku">
										<td>
											<a-input  placeholder="请输入" v-model="item.name"></a-input>
										</td>
										
										<td>
											<a-input-number v-model="item.price"  placeholder="请输入" :precision="2" :min="0" />
										</td>
										<td>
											<a-input-number v-model="item.need_time"  placeholder="请输入" :precision="0" :min="0" />
										</td>
										<td>
											<div class="clickAct" @click="delSkuItem(index)">
												<i class="iconfont iconbtn_close ft20 cl-notice "/>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
							<div class="">
								<a-button type="primary" icon="plus" ghost @click="addSkuItem()">添加</a-button>
							</div>
							
						</a-form-model-item>
						
						
						<a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
						      <a-button type="primary" @click="onSubmit" :loading="confirmLoading">
						        确定保存
						      </a-button>
						</a-form-model-item>
					</a-form-model>	
					
				</div>
			</div>
		</a-spin>
	</div>
</template>

<script>
	import uploadImg from '../../components/upload/upload.vue';
	export default{
		components:{
			uploadImg,
		},
		data(){
			return {
				loading:false,
				confirmLoading:false,
				labelCol: { span: 4 },
			    wrapperCol: { span: 20 },
				is_multi:0,
				sku:[],
				mendian:[],
				form: {
					project_id:0,
					name:'',
					photo:'',
					price:'',
					is_sku:0,
					num:1,
					need_time:90,
					step:20,
					bg:'08:00',
					end:'21:00',
					yue_num:0,
					sort:100,
				}
			}
		},
		created() {
			if (!this.$route.query.id) {
				this.form.project_id=0;
			} else {
				this.form.project_id = parseInt(this.$route.query.id);
			}
			this.loaddata();
		},
		computed:{
			getTitle(){
				if(this.form.project_id==0){
					return '添加预约项目类型';
				}else{
					return '编辑预约项目类型';
				}
			}
		},
		methods:{
			addSkuItem(){
				this.sku.push({
					sku_id:0,name:'',need_time:90,price:''
				});
			},
			delSkuItem(index){
				this.sku.splice(index,1);
			},
			loaddata(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/getYuyueProjectForm',{
					project_id:this.form.project_id
				}).then(res=>{
					this.form = res.detail;
					this.is_multi = res.is_multi;
					this.sku = res.sku;
					this.mendian = res.mendian;
					this.loading=false;
				}).catch(res=>{
					this.loading=false;
				})
			},
			onSubmit() {
				// console.log(this.form);
				// return;
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$http.api('admin/saveYuyueProject',{
					project_id:this.form.project_id,
					name:this.form.name,
					photo:this.form.photo,
					price:this.form.price,
					is_sku:this.form.is_sku,
					num:this.form.num,
					need_time:this.form.need_time,
					step:this.form.step,
					bg:this.form.bg,
					end:this.form.end,
					yue_num:this.form.yue_num,
					sort:this.form.sort,
					sku:this.sku,
					mendian:this.mendian
				}).then(res=>{
					this.$message.success('保存成功',1,()=>{
						this.confirmLoading=false;
						this.$router.go(-1);
					});
				}).catch(res=>{
					this.confirmLoading=false;
				})
			},
			
		}
	}
</script>

<style>
	
</style>